@import 'antd/dist/antd.min.css'

.logo
    height: 63px
    width: 350px
    background: url("../images/buev-logo.svg") white 20px 50% no-repeat
    background-size: 120px
    padding: 30px 0 0 110px
    font-weight: bold
    line-height: 0

.ant-layout-header
    box-sizing: border-box

.site-layout .site-layout-background
    background: white
    padding: 0

.grid-nav
    margin-bottom: 20px

.ant-page-header
    padding-left: 0
    padding-top: 0

.inline-form-label
  display: inline-block
  padding: 0 20px
  line-height: 32px

hr.hr
  border: none
  border-bottom: solid 1px #CCC
  margin: 0 0 20px 0

.strong
  font-weight: bold

.mb-20
  margin-bottom: 20px

.mt-20
  margin-top: 20px